@use "../../styles/partials/global.scss" as *;
@use "../../styles/partials/mixins.scss" as *;
@use "../../styles/partials/typography.scss" as *;
@use "../../styles/partials/variables.scss" as *;

.CTA {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__text {
    flex-wrap: wrap;
    width: 96%;
    font-size: 2.3rem;
    margin-bottom: 1rem;
    text-align: center;
    font-family: "DMSans";
    color: white;

    @include tablet {
      font-size: 3rem;
    }
  }
  &__title {
    font-size: 6rem;
    text-align: center;
    font-family: "DMSans";
    color: white;
    margin-top:2rem;

    @include tablet {
    text-align: center;
    font-family: "DMSans";
    color: white;
    }
    @include desktop {
      font-size:10rem;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include desktop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 73%;
    }
  }

  &__item {
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    font-size: 2rem;
    color: #0cc0df;
    margin: 2rem;
    border: 2px solid #0cc0df;
  }

  &__stats-wrapper {

    flex-direction: column;
    display: flex;
   
    margin: 2rem;
    @include tablet {
    
    flex-direction: row;
   
    }
  }
}
