@use "../../styles/partials/global.scss" as *;
@use "../../styles/partials/mixins.scss" as *;
@use "../../styles/partials/typography.scss" as *;
@use "../../styles/partials/variables.scss" as *;

.hero {

  &__circle {
    width: 100%;
    height: 69%;
    background-color: white;
    padding-top: 8rem;
    margin: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 60vw;
    border-top-right-radius: 60vw;
    border: 3px solid #0cc0df;

    @include tablet {
      padding-top: 12rem;
      width: 75%;
    
    }

  @include desktop {
    width: 100%;
    height: 69%;
    background-color: white;
    padding-top: 15rem;
    margin: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 60vw;
    border-top-right-radius: 60vw;
    border: 3px solid #0cc0df;
  }
}

  &__image2 {
    width: 60%;
    height: 70%;
    margin-bottom: 2rem;
    margin-left: 1rem;

    @include tablet {
      width: 60%;
    }
    

    @include desktop {
      width: 100%;
      }

      @include big-desktop {
        width: 75%;
      }
    
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

}
}
