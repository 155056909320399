@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.contact {
    &__email-title,&__prompt {
        font-size: 2rem;
        color:#0cc0df;
        font-family: "DMSans";
        font-weight: 600;
        
    }
    &__prompt {
        margin:1rem;
    }
    &__email {
        width: 80%;
        height: 2.5rem;
        border-radius: 1rem;
        margin:1rem;
       border: 2px solid #0cc0df;
    }
    &__msg {
        border-radius: 1rem;
        width: 80%;
        height: 50%;
        border: 2px solid #0cc0df;
    }
    &__submit {
        font-size: 2rem;
        border-radius: 1rem;
        padding:1rem;
        width:10rem;
        margin-top:1rem;
        background-color: #0cc0df;
        border:2px solid #0cc0df;
        color:white;
        font-weight: "DMSans";
        &:hover {
            cursor: pointer;
        }
    }
    &__form {
        display:flex;
        flex-direction: column;
        margin:1.6rem;
        border: 2px solid #0cc0df;
        padding:1.5rem;
        border-radius:1rem;
        width: 50vw;
        height: 50vh;
        align-items: center;
    justify-content: center;
    }

    &__github, &__linkedin {
        width:10rem;
        height:10rem;
    }

    &__wrapper {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }

    &__email-wrapper {
        display: flex;
    flex-direction: column;
    align-items: center;
    }
}