@use "../../styles/partials/global.scss" as *;
@use "../../styles/partials/mixins.scss" as *;
@use "../../styles/partials/typography.scss" as *;
@use "../../styles/partials/variables.scss" as *;

.footer {
  margin-bottom:1rem;
  &__img {
    width: 2rem;
    height: 2rem;
  }

  &__text {
    font-family: "DMSans";
    font-size: 1.5rem;
    color: #0cc0df;
    font-weight: 600;
    margin: 0 1.6rem;
  }
  &__text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
}
