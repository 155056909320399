@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.homepage {
  display: flex;
  flex-direction: column;
  &__title {
    color: white;
    font-size: 6rem;
  }

  &__image {
    border-radius: 4rem;
    width: 60rem;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);

    @include tablet {
      border-radius: 4rem;
      width: 50rem;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
    }
    @include desktop {
      border-radius: 4rem;
      box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
      width: 69rem;
    }
  }
  &__hero-wrapper {
    display: flex;
    animation: wave 1s infinite;
    align-content: space-between;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // flex-wrap: nowrap;
    background: linear-gradient(
      to left,
      rgba(12, 192, 223, 0.9),
      rgba(119, 201, 212, 0.8)
    );
    
    // background-size: 200% 100%;
    // height: calc(100vh - 1px); 
    // overflow-y: scroll;
    
   

    @include tablet {
      display: flex;
      align-content: space-between;
      align-items: center;
      flex-direction: column;
      
    }

    @include desktop {
      height:100vh;
      display: flex;
      align-content: space-between;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      background: linear-gradient(
        to left,
        rgba(12, 192, 223, 0.9),
        rgba(119, 201, 212, 0.8)
      );
      // background-size: 200% 100%;
    }
  }
  &__main-comps {
    display: flex;
    flex-direction: column;
  }
  @keyframes wave {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 50% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
 
}

