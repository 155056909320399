@use "./variables" as *;

@mixin mobile {
  @media (min-width: $mobileBreakPoint) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tabletBreakPoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktopBreakpoint) {
    @content;
  }
}

@mixin big-desktop {
  @media (min-width: $desktopBreakpoint) {
    @content;
  }
}