
  @font-face {
    font-family: "DMSans";
    src: url("../../assets/fonts/DMSans-MediumItalic.ttf") format("truetype");
 font-weight: 400;
    font-style: medium;
  }
  
  @font-face {
    font-family: "DMSans";
    src: url("../../assets/fonts/DMSans-Regular.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }