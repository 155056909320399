@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.projects {
  &__capstone,
  &__AC,
  &__BM,&__avanade {
    border: 3px solid #0cc0df;
    border-radius: 1rem;
    margin: 1.6rem;
    padding: 1rem;
  }
 &__avanade, &__AC {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
    display: flex;
    flex-direction: row-reverse;
    }
  }
  &__BM {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }
  
  }

  &__info {
    font-size: 1.5rem;
    color: #0cc0df;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__ff-img1 {
    width: 55rem;
    height: 30rem;
  }

  &__cap-img1{
    width: 50rem;
    height: 30rem;
    @include tablet {
    width: 65rem;
    height: 38rem;
    border-radius: 1rem;
    }
  }
  &__capstone-wrapper, &__avanade-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.6rem;
  }
  &__capstone {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
    flex-direction: row;
    }
  }
  &__AC-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.6rem;
  }
  &__tech {
    font-family: "DMSans";
    margin-top: 1rem;
    font-size: 1rem;
    text-align: center;
    color: #0cc0df;
    font-weight: 600;

    @include tablet {
      font-size: 2rem;
    }
  }
  &__BM-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.6rem;
  }

  &__description {
    font-size: 1rem;
    text-align: center;
    font-family: "DMSans";

    @include tablet {
    font-size: 2rem;
    }
  }
  &__repo, &__demo {
    border:#0cc0df;
    background-color: #0cc0df;
    color:white;
    padding:0 2rem;
    border-radius: 1.5rem;
    font-weight:600;
    width: 2rem;
    height: 2rem;
    font-size:1rem;
    width:100%;
    margin-top: 2rem;
    &:hover {
      cursor: pointer;
      background-color: white;
      color: #0cc0df;
      border:2px solid #0cc0df;
    }
    @include tablet {
    border:#0cc0df;
    background-color: #0cc0df;
    color:white;
    padding:0 2rem;
    border-radius: 1.5rem;
    font-weight:600;
    width: 5rem;
    height: 5rem;
    font-size:1.5rem;
    width:100%;
    margin-top: 2rem;
    &:hover {
      cursor: pointer;
      background-color: white;
      color: #0cc0df;
      border:2px solid #0cc0df;
    }
    }
  }
  &__demo {
    margin-left:1rem;
  }
 

  &__vid {
    width: 50%;
    height: 100%;
    border-radius: 1rem;
    margin:1rem;
  }

  &__btn-wrapper {
    display: flex;

  }
}
