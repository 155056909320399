@use "../../styles/partials/global.scss" as *;
@use "../../styles/partials/mixins.scss" as *;
@use "../../styles/partials/typography.scss" as *;
@use "../../styles/partials/variables.scss" as *;

.header {
  margin: 1.6rem;

  &__about,
  &__projects,
  &__contact {
    color: black;
    font-size: 2rem;
    margin: 1rem;
    font-family: "DMSans";

    &:hover {
      border-bottom: 2px solid #0cc0df;
    }

  }
 &__about.active,
&__projects.active {
  border-bottom: 2px solid #0cc0df;
}
&__contact.active {
  background-color: white;
  color:#0cc0df;
  border:2px solid #0cc0df;
}
  &__logo {
    width: 12rem;
    height: 10rem;
  }

  &__contact {
    background-color: #0cc0df;
    color: white;
    border-radius: 1rem;
    padding: 0rem 1rem;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  &__icons-wrapper {
    display: flex;
  }
  a {
    text-decoration: none;
  }
}
