@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.NF {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__logo {
    width: 10rem;
    height: 10rem;
  }
  &__text {
    font-family: "DMSans";
    font-size: 4rem;
  }
}
