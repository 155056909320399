@use "../../styles/partials/global" as *;
@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.about {
  margin: 1.6rem;
  font-family: "DMSans";

  &__mega-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__img {
    width: 35rem;
    height: 35rem;
    border-radius: 20rem;
    object-fit: cover;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border:3px solid #0cc0df;

    @include desktop {
      border-radius: 20rem;
    width: 40rem;
    height: 55rem;
    object-fit: cover;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 2.6rem;

    @include desktop {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 2.6rem;
    }

    @include big-desktop {
      width: 80%;
    }
  }
  &__game-header {
    text-align: center;
    font-size: 2.5rem;
    color: #0cc0df;
    font-weight: 600;
  }
  &__game {
    border: 4px solid #0cc0df;
    margin-top: 1rem;
    border-radius: 2rem;
    padding: 1rem;
    width: 80%;
    overflow-x: auto;

  }
  &__text {
    margin: 3.6rem;
    line-height: 3rem;
    text-align: center;
    font-size: 1.8rem;
  }
  &__highlight {
    background-color: rgba(12, 192, 223, 0.7);
    color: white;
    border-radius: 0.5rem;
  }

  &__text2 {
    margin: 1.6rem;
    margin-top: 1rem;
    line-height: 2.5rem;
    text-align: center;
    font-size: 1.8rem;
  }
  &__gameQ {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  &__game-Q1,
  &__game-Q2,
  &__game-Q3 {
    display: flex;
    flex-direction: column;
  }

  &__q {
    margin: 1rem;
    font-size: 1.5rem;
    background-color: #0cc0df;
    color: white;
    padding: 1rem;
    border-radius: 1rem;
    &:hover {
      cursor: pointer;
      background-color: white;
      color: #0cc0df;
      border:2px solid #0cc0df;
    }
  }
  &__q.correct1,
  &__q.correct2,
  &__q.correct3 {
    color: #0cc0df;
    margin: 1rem;
    background-color: white;
    border: 2px solid #0cc0df;
  }
  &__q.incorrect1,
  &__q.incorrect2,
  &__q.incorrect3 {
    text-decoration: line-through;
  }
}
